import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { InteractionTextSelection } from "../../examples/utilities/Interaction";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 id="text-selection" className="h1 font-weight-bold mt-3">
  Text selection
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#text-selection" aria-label="anchor"></a>
    </h2>
    <CodeBlock code={InteractionTextSelection} mdxType="CodeBlock">
  Change the way in which the content is selected when the user interacts with
  it.
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      